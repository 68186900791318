import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Button, Snackbar, Stack, Typography } from '@mui/material';
import {
  Info as InfoIcon,
  DoDisturbOn as DoDisturbOnIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { notificationBackgroundColorMap, notificationColorMap } from 'utils/colorMappers';

const ToastNotification = ({
  bottomProperty,
  hideCloseButton = false,
  horizontalPosition,
  length = 150,
  node,
  onClose = () => null,
  open = false,
  subtitle,
  title,
  type,
  verticalPosition,
}) => {
  const [expanded, setExpanded] = useState(false);

  const getAlertProps = () => {
    const alertProps = {};
    if (!hideCloseButton) {
      alertProps.onClose = onClose;
    }

    switch (type) {
      case 'info':
        alertProps.severity = 'info';
        alertProps.color = 'info';
        alertProps.icon = <InfoIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'success':
        alertProps.severity = 'success';
        alertProps.color = 'success';
        alertProps.icon = <CheckCircleIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'error':
        alertProps.severity = 'error';
        alertProps.color = 'error';
        alertProps.icon = <DoDisturbOnIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'warning':
        alertProps.severity = 'warning';
        alertProps.color = 'warning';
        alertProps.icon = <ErrorIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
    }
    return alertProps;
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        horizontal: horizontalPosition,
        vertical: verticalPosition,
      }}
      sx={{ bottom: bottomProperty }}
    >
      <Alert
        sx={{
          backgroundColor: notificationBackgroundColorMap[type],
          borderLeft: `0.185rem solid ${notificationColorMap[type]}`,
          maxWidth: '22rem',
        }}
        {...getAlertProps()}
      >
        {React.isValidElement(node) ? (
          { ...node }
        ) : (
          <>
            <AlertTitle>
              <Typography component="label" variant="body1" color="text.primary">
                {title}
              </Typography>
            </AlertTitle>
            <Stack alignItems="flex-start">
              <Typography component="label" variant="caption2" color="text.primary">
                {expanded || subtitle.length < length
                  ? subtitle
                  : `${subtitle.slice(0, length)}...`}
              </Typography>
              {subtitle.length > length && (
                <Button
                  size="small"
                  variant="text"
                  color={type}
                  onClick={() => setExpanded(!expanded)}
                  sx={{
                    padding: '0rem',
                    minWidth: 'fit-content',
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                  disableRipple
                >
                  <Typography component="span" variant="label2">
                    {expanded ? 'Read Less' : 'Read More'}
                  </Typography>
                </Button>
              )}
            </Stack>
          </>
        )}
      </Alert>
    </Snackbar>
  );
};

ToastNotification.propTypes = {
  bottomProperty: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  horizontalPosition: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
  length: PropTypes.number,
  node: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  verticalPosition: PropTypes.oneOf(['bottom', 'top']).isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
};

export default ToastNotification;
