import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_NAV_BAR_ITEMS } from 'queries/NavBar';

export const NavBarContext = createContext({});

const NavBarProvider = ({ children }) => {
  const { data, loading, error } = useQuery(GET_NAV_BAR_ITEMS, {
    context: { clientName: 'common' },
  });
  const parsedItems = data?.UniversalToolbar?.items
    ? JSON.parse(data.UniversalToolbar.items)
    : null;

  const contextValue = useMemo(
    () => ({ data: parsedItems, loading, error }),
    [parsedItems, loading, error],
  );

  return <NavBarContext.Provider value={contextValue}>{children}</NavBarContext.Provider>;
};

NavBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavBarProvider;

export const useNavBarContext = () => useContext(NavBarContext);
